<template>

    <div>

        <TestComponent @create-show="showCreateModal" @edit-show="showEditModal" @delete-submit="submitDeleteModal"
                       @edit-submit="submitEditModal" @create-submit="submitCreateModal"
                       :tabs="['User Data', 'Nav Items', 'Custom Data']" :objType="{one: 'user', multi: 'users'}"
                       tableTitle="Manage Users"
                       :data="users" nameField="username" :formFields="formFields"
                       :createNotSubmittable="createNotSubmittable" :editNotSubmittable="editNotSubmittable"
                       :columns="['username', 'firstname', 'lastname', 'email', 'type', 'phoneNumber', 'edit']"
                       :headings="{username: 'Username', firstname: 'First Name', lastname: 'Last Name', email: 'E-Mail', phoneNumber: 'Phone Number', type: 'Type', edit: 'Edit' }"
                       :sortable="['username', 'firstname', 'lastname', 'email', 'type', 'phoneNumber']"
                       :filterable="['username', 'firstname', 'lastname', 'email', 'type', 'phoneNumber']"
                       :orderBy="{column: 'username', ascending: true}">

            <!--Create Modal Fields-->
            <div slot="createModalInput">
                <b-form-group description="ex. +1 (999) 999-9999" label="Phone Number*">
                    <b-input-group>
                        <div class="input-group-prepend">
                <span class="input-group-text">
                  <fa-icon :icon="['fas', 'plus']" style="cursor:auto"/>
                </span>
                        </div>
                        <b-input-group-prepend>
                            <input placeholder="1" :class="{ 'border-danger': !newCode}" type="text" maxlength="3"
                                   size="1"
                                   class="form-control rounded-0" @keypress="isNumber" v-model="newCode">
                        </b-input-group-prepend>

                        <masked-input
                            placeholder="(999) 999-9999"
                            type="tel"
                            class="form-control"
                            :class="{ 'border-danger': !newPhone}"
                            v-model="newPhone"
                            :mask="['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]"
                            :guide="true">
                        </masked-input>
                    </b-input-group>
                </b-form-group>

                <label class="form-control-label">Type*</label>
                <b-form-select
                    :options="[{value:'admin', text:'Admin'}, {value:'enduser', text:'End User'}, {value:'service', text:'Service'}]"
                    :state="state(newType)" v-model="newType"/>
                <br>
                <br>

                <label class="form-control-label">Groups*</label>
                <v-client-table :columns="['groupname', 'select']" :data="groups" :options="groupsTable.options" :theme="groupsTable.theme" class="dataTable p-0 m-0 w-100">
                    <div slot="h__select" :id="allSelected" style="float: right">
                        <b-form-checkbox  @change="newGroup = selectAll()" color="success" style="float:right;"
                                          v-model="allSelected" ref="allSelectedCreate"/>
                    </div>
                    <div slot="select" slot-scope="props" style="float: right">
                        <b-form-checkbox v-model="newGroup" :value="props.row._id" color="success" :state="state(newGroup)"
                                         style="float:right;"></b-form-checkbox>
                    </div>
                </v-client-table>
            </div>

            <!--Create Modal Nav-->
            <div slot="createModalTabInput1">
                <table class="table">
                    <tr v-for="option in nav_options" :key="option._id">
                        <td><i style="cursor: auto; width: fit-content" :class="option.icon + ' icons font-lg d-block'"></i></td>
                        <td>{{ option.name }}</td>
                        <td>
                            <b-form-checkbox v-model="createSelected" :value="option._id" switch/>
                        </td>
                    </tr>
                </table>
            </div>

            <!--Create Modal Custom Tab-->
            <div slot="createModalTabInput2">

                <label class="form-control-label">Custom Claims</label>
                <ClaimsTable :editBool="false" :editClaims="[]"/>

                <br>

                <label class="form-control-label">Custom Fields</label>
                <b-textarea placeholder="Custom Fields" v-model="newCustomFields"></b-textarea>

            </div>

            <!--Edit Modal Fields-->
            <div slot="editModalInput">
                <b-form-group description="ex. +1 (999) 999-9999" label="Phone Number*">
                    <b-input-group>
                        <div class="input-group-prepend">
                <span class="input-group-text">
                  <fa-icon :icon="['fas', 'plus']" style="cursor:auto"/>
                </span>
                        </div>
                        <b-input-group-prepend>
                            <input placeholder="1" :class="{ 'border-danger': !editCode}" type="text" maxlength="3"
                                   size="1"
                                   @keypress="isNumber" class="form-control rounded-0" v-model="editCode">
                        </b-input-group-prepend>

                        <masked-input
                            placeholder="999 999 9999"
                            type="tel"
                            class="form-control"
                            :class="{ 'border-danger': !editPhone}"
                            v-model="editPhone"
                            :mask="['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]"
                            :guide="true"
                            placeholderChar="#">
                        </masked-input>
                    </b-input-group>
                </b-form-group>

                <label class="form-control-label">Type*</label>
                <select class="form-control border" v-model="editType">
                    <option value="admin">Admin</option>
                    <option value="enduser">End User</option>
                    <option value="service">Service</option>
                </select>
                <br>


                <v-client-table :columns="['groupname', 'select']" :data="groups" :options="groupsTable.options" :theme="groupsTable.theme" class="dataTable p-0 m-0 w-100">
                    <div slot="h__select" :id="allSelected" style="float: right">
                        <b-form-checkbox  @change="editGroup = selectAll()" color="success" style="float:right;"
                                          v-model="allSelected" ref="allSelectedEdit"/>
                    </div>
                    <div slot="select" slot-scope="props" style="float: right">
                        <b-form-checkbox v-model="editGroup" :value="props.row._id" color="success" :state="state(editGroup, true)"
                                         style="float:right;"></b-form-checkbox>
                    </div>
                </v-client-table>
            </div>

            <!--Edit Modal Nav-->
            <div slot="editModalTabInput1">
                <table class="table">
                    <tr v-for="option in nav_options" :key="option._id">
                        <td><i style="cursor: auto; width: fit-content" :class="option.icon + ' icons font-lg d-block'"></i></td>
                        <td>{{ option.name }}</td>
                        <td>
                            <b-form-checkbox v-model="editSelected" :value="option._id" switch/>
                        </td>
                    </tr>
                </table>
            </div>

            <!--Edit Modal Custom Tab-->
            <div slot="editModalTabInput2">

                <label class="form-control-label">Custom Claims</label>
                <ClaimsTable :editBool="true" :editClaims="editClaims"/>

                <br>

                <label class="form-control-label">Custom Fields</label>
                <b-textarea placeholder="Custom Fields" v-model="customFields"></b-textarea>

            </div>

        </TestComponent>

        <!--<b-card style="width: 100%; height: 100%;">
        <table style="width: 100%">
          <vue-nestable style="width: 100%" v-model="nav_options" :max-depth="1" childrenProp="false">
            <vue-nestable-handle style="width: 100%" slot-scope="{item}" :item="item">
              <tr style="width: 100%">
                <td><i style="cursor: auto;" :class="item.icon + ' icons font-lg d-block'"></i></td>
                <td>{{item.name}}</td>
                <td><b-form-checkbox v-model="createSelected" :value="item._id" switch/></td>
              </tr>
            </vue-nestable-handle>
          </vue-nestable>
        </table>
      </b-card>-->

    </div>

</template>

<script>
import iss from '../../../services/iss.js';
import fcr from '../../../services/fcr.js';
import TestComponent from '../Component.vue';
import claimsStore from "@/store/claimsStore";
import MaskedInput from 'vue-text-mask';
import ClaimsTable from "@/components/ClaimsTable";

export default {
    components: {
        ClaimsTable,
        TestComponent,
        MaskedInput,
        claimsStore
    },
    data: () => {
        return {
            users: [],
            curUser: {},
            nav_options: [],
            createSelected: [],
            editSelected: [],
            groups: [],
            allSelected: false,
            groupsTable:  {
                options: {
                    orderBy: {column: 'group', ascending: true},
                    headings: {
                        groupname: "Group",
                        select: 'Select'
                    },
                    sortable: ['groupname'],
                    filterable: ['groupname'],
                    sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                    initialPage: 1,
                    pagination: {
                        chunk: 5,
                        edge: false,
                        nav: 'scroll'
                    },
                    perPage: 10,
                    skin: 'table table-striped table-hover',
                },
                useVuex: false,
                theme: 'bootstrap4',
                template: 'default',
            },
            formFields: [{name: "username", type: "text", required: true, label: "Username*"}, {
                name: "password",
                type: "password",
                required: true,
                label: "Password*"
            },
                {name: "email", type: "email", required: true, label: "Email*"}, {
                    name: "firstname",
                    type: "text",
                    required: false,
                    label: "First Name"
                }, {name: "lastname", type: "text", required: false, label: "Last Name"},],
            newGroup: [],
            editGroup: [],
            newType: [],
            editType: [],
            newPhone: "",
            editPhone: "",
            newCode: "",
            editCode: "",
            errorMsg: {method: "", status: "", statusText: ""},
            claims: [],
            editClaims: [],
            customFields: '',
            newCustomFields: {}
        }
    },
    created() {
        this.getUsersInGroup();
        fcr.getNavInfo(localStorage.id).then(response => {
            this.nav_options = response.data;
            this.nav_options.forEach(function (option, index) {
                option.id = index
            })
        }).catch(error => {
            this.$mToast({
                title: error.response.status + ' Error',
                text: "Nav options couldn't be retrieved: " + error.response.statusText,
                style: 'error'
            });
        });
        iss.getAllGroups().then(response => {
            this.groups = response.data;
        });
        this.getClaims();
    },
    methods: {
        selectAll() {
            return this.allSelected ? this.groups.map(_ => _._id) : [];
        },
        getClaims() {
            iss.getUserClaims().then(response => {
                this.claims = response.data;
            });
        },
        getUsersInGroup() {
            iss.getUsersInGroup().then(response => {
                this.users = response.data;
            }).catch(error => {
                this.$mToast({
                    title: error.response.status + ' Error',
                    text: "Users couldn't be retrieved: " + error.response.statusText,
                    style: 'error'
                });
            });
        },
        showCreateModal() {
            this.createSelected = [];
            this.newGroup = [];
            this.newType = [];
            this.newCode = "";
            this.newPhone = "";
            this.newCustomFields = '';
        },
        showEditModal(item) {
            fcr.getCurrentNav(item._id).then(response => {
                this.editSelected = response.data.navbars;
            }).catch(error => {
                this.$mToast({
                    title: error.response.status + ' Error',
                    text: "Current nav information couldn't be retrieved: " + error.response.statusText,
                    style: 'error'
                });
            });
            this.editGroup = item.groups;
            this.editType = item.type;
            this.editCode = item.countryCode;
            this.editPhone = item.phoneNumber;
            this.editClaims = item.customClaims;
            this.customFields = JSON.stringify(item.customFields);
        },
        submitEditModal(user) {
            fcr.updateNav(user._id, this.editSelected).then(() => {
                user.phoneNumber = this.editPhone.slice(1, 4) + this.editPhone.slice(6, 9) + this.editPhone.slice(10, 14)
                user.countryCode = this.editCode;
                user.groups = this.editGroup;
                user.type = this.editType;
                user.customClaims = claimsStore.getters.getSelectedClaims();
                user.customFields = {};
                try {
                    user.customFields = JSON.parse(this.customFields);
                } catch (e) {
                    this.$mToast({
                        title: 'Error',
                        text: 'Custom fields could not be parsed',
                        style: 'error'
                    });
                }
                iss.editUser(user).then(() => {
                    this.$mToast({
                        title: 'Success',
                        text: "User updated",
                        style: 'success'
                    });
                    if (user._id === localStorage.getItem("id")) {
                        location.reload();
                    } else {
                        this.getUsersInGroup();
                    }
                }).catch(() => {
                    this.$mToast({
                        title: 'Error',
                        text: "User couldn't be updated",
                        style: 'error'
                    });
                })
            }).catch(() => {
                this.$mToast({
                    title: 'Error',
                    text: "Nav couldn't be updated",
                    style: 'error'
                });
            });
        },
        submitDeleteModal(id, reload = true) {
            iss.deleteUser(id).then(() => {
                if (reload) {
                    this.getUsersInGroup();
                    this.$mToast({
                        title: 'Success',
                        text: "User deleted",
                        style: 'success'
                    });
                }
            }).catch(error => {
                this.$mToast({
                    title: error.response.status + ' Error',
                    text: "User couldn't be deleted: " + error.response.statusText,
                    style: 'error'
                });
            })
        },
        submitCreateModal(user) {
            user.groups = this.newGroup;
            user.type = this.newType;
            user.phoneNumber = this.newPhone.slice(1, 4) + this.newPhone.slice(6, 9) + this.newPhone.slice(10, 14);
            user.countryCode = this.newCode;
            user.customClaims = claimsStore.getters.getSelectedClaims();
            user.customFields = {};
            try {
                user.customFields = JSON.parse(this.newCustomFields);
            } catch (e) {
                this.$mToast({
                    title: 'Error',
                    text: 'Custom fields could not be parsed',
                    style: 'error'
                });
            }
            iss.createUser(user).then(response => {
                fcr.createUserNav(response.data._id, this.createSelected).then(() => {
                    this.$mToast({
                        title: 'Success',
                        text: "User created",
                        style: 'success'
                    });
                    this.getUsersInGroup();
                }).catch(error => {
                    this.$mToast({
                        title: error.response.status + ' Error',
                        text: "Nav couldn't be created: " + error.response.statusText,
                        style: 'error'
                    });
                })
            }).catch(error => {
                this.$mToast({
                    title: error.response.status + ' Error',
                    text: "Users couldn't be created: " + error.response.statusText,
                    style: 'error'
                });
            })
        },
        isNumber(event) {
            if (!parseInt(event.key) && event.key !== '0') {
                event.preventDefault();
            }
        },
        state(arr, edit) {
            if (edit) {
                return this.groups.every(_ => {
                    return !arr.includes(_._id)
                }) ? false : null;
            }
            return arr.length > 0 ? null : false;
        }
    },
    computed: {
        editNotSubmittable() {
            return this.editGroup.length === 0 || !this.editCode || !this.editPhone;
        },
        createNotSubmittable() {
            return this.newGroup.length === 0 || this.newType.length === 0 || !this.newCode || !this.newPhone;
        }
    }
}
</script>

<style scoped>
.table td {
    border: none;
}
</style>